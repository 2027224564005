import React from 'react'
import PropTypes from 'prop-types'

const DetailText = function DetailText({ header, body }) {
  return (
    <div className="mb-10">
      <div className="text-2xl">{header}</div>
      <div className="text-sm">{body}</div>
    </div>
  )
}

DetailText.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

export default DetailText
