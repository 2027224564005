/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Tree from '../images/tree-filled.svg'

import './layout.css'

const Layout = ({ children, location, crumbLabel }) => (
  <div className="h-screen">
    <div
      className="
          w-full
          h-11
          fixed
          top-0
          text-center
          py-2
          whitespace-no-wrap
          z-50
          bg-black
          border-white
          border-solid
          border-b
        "
    >
      <Breadcrumb location={location} crumbLabel={crumbLabel} />
    </div>
    <main className="pt-12 sm:pt-24 grid grid-cols-1 sm:grid-cols-12 sm:gap-8 px-4 sm:p-0">
      {children}
    </main>
    <div className="relative border-white border-b-2">
      <Tree className="relative -m-px-3 left-rem-4" />
    </div>
    <div className="text-right py-1 px-2">
      <p>
        &copy;
        {' '}
        {(new Date()).getFullYear()}
        {' '}
        Sleepy Fox Studios LLC
      </p>
    </div>
  </div>
)

Layout.defaultProps = {
  location: {},
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  crumbLabel: PropTypes.string.isRequired,
}

export default Layout
