import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const IMAGES = {
  sleepyFoxLogotype: 'sleepyFoxLogotype',
  sleepyFoxEyeRaiseLogotype: 'sleepyFoxEyeRaiseLogotype',
  jewelTonesBlastIcon: 'jewelTonesBlastIcon',
  jewelTonesTwistIcon: 'jewelTonesTwistIcon',
  googlePlayStoreBadge: 'googlePlayStoreBadge',
  appleAppStoreBadge: 'appleAppStoreBadge',
}

const Image = ({ filename, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      sleepyFoxLogotype: file(relativePath: { eq: "logotype-outline.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sleepyFoxEyeRaiseLogotype: file(relativePath: { eq: "logotype-outline-eyeraise.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jewelTonesBlastIcon: file(relativePath: { eq: "jewel-tones-blast-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jewelTonesTwistIcon: file(relativePath: { eq: "jewel-tones-twist-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      googlePlayStoreBadge: file(relativePath: { eq: "download-google-play-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      appleAppStoreBadge: file(relativePath: { eq: "download-apple-app-store.png" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  return <Img fluid={data[filename].childImageSharp.fluid} {...rest} />
}

Image.defaultProps = {
  filename: IMAGES.sleepyFoxLogotype,
}

Image.propTypes = {
  filename: PropTypes.string,
}

export default Image
